export const NavLinkData = [
    {
        img: './images/jumbotronImg.png'
    },
    {
        img: './images/jumbotronImgg.png',
    },


    {
        title: "Home",
        link: "Riwayat Pembelian"
    },

    {
        title: "Layanan",
        link: "Jadwal",
    },

    {
        title: "Blog",
        link: "Kelas saya",
    },

    {
        title: "About Us",
        link: "Webinar",
    },

    {
        link: "Konsultasi",
    },

    {
        link: "Kelas yang pernah di ikuti",
    },
]