import React from 'react'

const Teks = () => {
    return (
        <div className='teks'>
            <h1 className='ml-10 font-bold text-2xl mt-10 relative text-[#002157]'>Pendiri Konseling Satir</h1>
        </div>
    )
}

export default Teks
