import React from 'react'

const teks = () => {
    return (
        <div className='teks'>
            <h1 className='text-center font-bold text-2xl mt-[50px] text-[#002157]'>Apa Kata Mereka</h1>
        </div>
    )
}

export default teks